<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">탈퇴</v-btn>
      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">회원탈퇴</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container grid-list-xs class="pb-0 mb-0">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12">
              <!-- <v-divider class="white--text"></v-divider> -->
              <v-card class="elevation-0 mt-3 mb-3 blue-grey--text">
                <v-card-text>
                  <p>그동안 본 솔루션을 이용해주셔서 감사했습니다.</p>
                  <p>탈퇴사유가 솔루션의 불편함 때문이 아니기를 진심으로 바라오며,</p>
                  <p>다음에 뵐 때는 더욱 발전된 모습으로 뵙겠습니다.</p>
                  <p>탈퇴가 완료되면 자동으로 <strong>구글계정 > 보안</strong> 페이지로 이동합니다.</p>
                  <p>반드시 <strong>계정 액세스 권한이 있는 타사 앱</strong>에서 본 앱을 삭제해 주시기 바랍니다.</p>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- <v-col cols="12" xs="12">
              <v-form>
                <v-textarea
                  outline
                  v-model="form.memo"
                  label="탈퇴사유"
                ></v-textarea>
              </v-form>
            </v-col> -->
          </v-row>
        </v-container>
      </v-card-text>
    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 650,
      zIndex: 300
    },

    form: {
      email: '',
      memo: ''
    }
  }),

  mounted () {
    this.initForm() // 폼 초기화

    if (this.$store.state.ui) {
      // 이미 로그인된 상태라 저장소에 유저정보가 저장된 경우
      this.dialog = true

      // 이메일은 저장된 토큰에서 가져온다.
      this.form.email = this.$store.state.ui.email
    } else {
      // 이도저도 아니면 뭔가 잘못되었으니 초기화면으로
      this.redirect('/')
    }
  },

  methods: {
    dummy () {
    },
    sbpop (msg) {
      this.$store.commit('SB_POP', { msg })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 폼 초기화
    initForm () {
      this.form = {
        email: '',
        memo: ''
      }
    },
    async agree () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        if (await pop.open('확인', '계속 진행하시겠습니까?', { color: 'error' })) {
          const { data } = await this.$axios.post('auth/requestLeave', this.form)
          if (!data.success) throw new Error('탈퇴과정에 에러가 발생하였습니다!')

          this.dialog = false
          this.$store.dispatch('LOGOUT') // 로그아웃 처리
          // 구글 계정화면으로 이동
          window.location.href = 'https://myaccount.google.com/security'
        }
      } catch (e) {
        this.sbpop(e)
      }
    },

    cancel () {
      // 취소시 앞 페이지로 돌아간다
      this.initForm() // 폼 초기화
      this.redirect('/auth/regist2')
      this.dialog = false
    },

    redirect (to = '') {
      this.dialog = false
      this.$router.push(to)
    }
  }
}
</script>
